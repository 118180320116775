.o-header {
  background: $white;
  padding-top: 46px;
  transition: background ease-in-out 0.1s;
  z-index: 2; // so it doesn't show on top of modal

  &--is-active {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    background: $dark-two;

    .container,
    .navbar {
      height: 100%;
    }
  }

  &.fixed-top {
    padding-top: 80px;
    position: fixed;

    .o-header-dropdown-wrapper {
      top: 54px;
    }
  }

  &--desktop {
    overflow: hidden;
    padding-top: 126px;
    background: $white;

    .o-header {
      &__container {
        padding-right: 15px;
        padding-left: 15px;
        max-width: 1140px;
        width: 100%;
        margin: auto;
        // background: $white;
        background: transparent;
      }

      &__logo {
        flex: 0 0 auto;
        height: 30px;
        display: flex;
        align-items: center;

        a {
          width: 223px;
        }
      }

      &__cta {
        display: flex;
        flex: 0 0 auto;

        // a {
        //   &:first-child {
        //     margin-right: 12px;
        //   }
        // }
      }

      &__nav {
        flex: 1 0 auto;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      &__content {
        display: flex;
        align-items: center;
        padding-top: 46px;
      }
    }
  }

  @media #{$c-md-up} and #{$c-lg-down} {
    &--desktop {
      padding-top: 98px;
      .o-header__container {
        max-width: 720px;
      }

      .o-header__logo {
        width: 30px;
      }

      .o-header__content {
        padding-top: 18px;
      }
      .o-header__dropdown-container .o-header__main-nav{
        margin-right: 15px;
      }
    }
  }

  @media #{$c-lg-up} and #{$c-xl-down} {
    &--desktop {
      .o-header__container {
        max-width: 960px;
      }


    }
  }

  @media #{$c-md-down} {
    padding-top: 80px;
  }

  @media #{$c-md-down} {
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: none;

    &--desktop {
      display: none;
    }

    &.fixed-top {
      top: 0;
      padding-top: 8px;
      position: fixed;
    }

    .container {
      max-width: none;
      padding: 0;
    }
  }

  @media #{$c-md-up} {
    &--mobile {
      display: none;
    }

    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  @media #{$c-md-up} and #{$c-lg-down} {
    &.fixed-top {
      position: fixed;

      &.fixed-top {
        +main {
          margin-top: 98px;
        }
      }
    }
  }

  @media #{$c-lg-up} {
    &.fixed-top {
      &.fixed-top {
        +main {
          margin-top: 126px;
        }
      }
    }
    .navbar-brand--with-animation {
      display: none;
    }
  }

  @media #{$c-md-up} {
    &--is-active {
      position: relative;
      top: 0;
      height: auto;
      left: 0;
      right: 0;
      background: $white;
    }
  }

}

/* Header */
.navbar {
  padding: 22px 0 22px 0;
  // background: $white;
  background: transparent;
  transition: none;

  &-nav {
    margin-right: 30px;
    position: relative;
    // z-index: 10000;
  }

  &-cta {
    display: flex;

    a,
    button {
      &:first-of-type {
        margin-right: 12px;
      }

      &:last-of-type {
        padding: 7px 18px;
      }
    }
  }

  &-brand {
    height: 30px;
    background: url("../images/fw-logo.svg");
    background-size: 223px 30px;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0;
    margin-right: 0px;
    flex: 1 0 223px;
  }
  &-brand-animated {
    height: 30px;
    // background: url("../images/fw-logo.svg");
    background-size: 223px 30px;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0;
    margin-right: 0px;
    flex: 1 0 223px;
    text-align: left;
    svg {
      max-width: 223px;
    }

  }
  .subnav {
    &__list {
      list-style: none;
      padding: 0;
      flex: 1 0 auto;
    }

    &__list-item {
      margin-bottom: 28px;

      >ul>li {
        margin-bottom: 21px;
        padding-left: 35px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      button,
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: color ease-in-out $anim-speed;

        i {
          svg {
            fill: #dfdbdb;
            transition: fill ease-in-out $anim-speed, stroke ease-in-out $anim-speed;
            flex: 0 0 auto;

            &.subnav__list-item-wurks,
            &.subnav__list-item-design {
              stroke: #DFDBDB;
            }
          }
        }
      }

      .subnav {
        display: flex;
        flex-direction: column;

        &__list-item {
          display: flex;
        }
      }

      i {
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      &--tech {
        >.subnav {
          margin-top: 20px;
          margin-bottom: 15px;
        }

        >a,
        >button {
          position: relative;
          padding-left: 0;
          padding-right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 23px;
            height: 23px;
          }

          &:after {
            content: "";
            position: absolute;
            right: -17px;
            background: url(../images/icons/icon-arrow-small.svg) no-repeat center;
            width: 20px;
            height: 20px;
            transition: transform ease-in-out $anim-speed, background ease-in-out $anim-speed;
          }

          &:hover,
          &:active,
          &:focus {
            &:after {
              background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
            }
          }

          &[data-expanded="true"] {
            color: #A8AEB1;

            &:after {
              background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
              transform: rotate(-180deg);
            }

            i {
              svg {
                fill: #A8AEB1;
              }
            }
          }
        }
      }
    }

    .subnav {
      margin-top: 20px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 1px;
        top: -12px;
        bottom: -6px;
        left: 9px;
        background: #32424C;
      }
    }
  }

  &-collapse {
    &.collapsing {
      transition: none;
    }
  }

  @media #{$c-lg-down} {
    &-brand, &-brand--with-animation {
      background: url("../images/fw-logo-sm.svg") no-repeat;
      flex: 0 0 30px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      padding: 0px 0px 0px 0px;
      margin-right: 0px;
      svg {
        display: none;
      }
    }
    &-brand-animated {
      display: none;
    }
  }

  @media #{$c-md-up} {
    .subnav {
      position: absolute;
      box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
      border: solid 1px #d3cece;
      background-color: #fcfcfc;
      list-style: none;
      min-width: 206px;
      border-radius: 4px;
      top: 42px;
      left: calc(50% - 100px);
      display: flex;

      // display: none;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: -9px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid white;
        transform: rotate(90deg);
      }

      &:before {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        top: -12px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #d3cece;
        transform: rotate(90deg);
      }

      &__list {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;
        padding-right: 0;
        min-width: 206px;

        li {
          padding-right: 24px;
          margin-bottom: 0;
        }
      }

      &--why {
        &:after {
          left: calc(206px / 2);
        }

        &:before {
          left: calc(206px / 2 - 1px);
        }
      }

      &__list-tech {
        min-width: 206px;
        padding-left: 10px;

        &[data-hidden="true"] {
          display: none;
        }
      }

      &__list-item {

        a,
        button {
          color: $dark;
          font-size: 13px;
          padding-top: 14px;
          padding-bottom: 14px;

          i {
            margin-right: 13px;

            svg {
              fill: $dark;

              &.subnav__list-item-wurks {
                stroke: $dark;
              }

              &.subnav__list-item-design {
                stroke: $dark;
                fill: $white;
              }
            }
          }

          &:focus,
          &:active,
          &:hover {
            text-decoration: none;
            color: $outer-space-20;

            i {
              svg {
                fill: $outer-space-20;

                &.subnav__list-item-wurks {
                  stroke: $outer-space-20;
                }

                &.subnav__list-item-design {
                  stroke: $outer-space-20;
                  fill: $white;
                }
              }
            }
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .subnav__list-item--tech {
        display: flex;

        button {
          width: 100%;
          justify-content: flex-start;

          &::after {
            transform: rotate(-90deg);
          }

          &:after {
            content: "";
            position: absolute;
            right: -17px;
            background: url(../images/icons/icon-arrow-small-dark.svg) no-repeat center;
            width: 20px;
            height: 20px;
            transition: transform ease-in-out $anim-speed, background ease-in-out $anim-speed;
          }

          &:hover,
          &:active,
          &:focus {
            &:after {
              background: url(../images/icons/icon-arrow-small-dark-hover.svg) no-repeat center;
            }
          }
        }

        ul {
          display: none;
        }
      }

      &__list-item-optim--mobile {
        display: none;
      }
    }
  }

  @media #{$c-md-down} {
    padding: 24px 15px 24px 15px;

    &--desktop {
      display: none;
    }

    .subnav {
      &__list-item-optim--desktop {
        display: none;
      }

      &__list-tech {
        display: none;
      }

      a,
      button {

        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          color: #A8AEB1;

          i {
            svg {
              fill: #A8AEB1;

              &.subnav__list-item-wurks {
                stroke: #A8AEB1;
              }

              &.subnav__list-item-design {
                stroke: #A8AEB1;
                fill: $dark;
              }
            }
          }
        }

        i {
          svg {
            &.subnav__list-item-design {
              stroke: #dfdbdb;
              fill: $dark;
            }
          }
        }
      }
    }

    .nav-link {
      color: $white;
      font-size: 26px;
      padding-right: 30px;

      i {
        display: flex;
      }

      &:hover {
        &:after {
          background-image: url("../images/icons/arrow-large-hover.svg");
        }
      }

      // padding-right: 30px;
      margin-bottom: 0;
      font-weight: 300;
      letter-spacing: -0.65px;
    }

    .nav-item {
      ul {
        padding-left: 0;
        margin-top: 10px;
      }
    }

    &-collapse {
      overflow-x: scroll;
      // height: calc(100% - 31px);
      height: calc(100% - 16px);
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;
    }

    &-cta {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1199.98px) {
    &-nav {
      margin-right: 15px;
    }
  }
}

.navbar--scrolled {
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);

  .o-header__content {
    padding-top: 0;
  }
}

.nav-item {
  position: relative;

  ul {

    li a,
    button {
      color: #dfdbdb;
      font-size: 14px;
    }
  }
}

.nav-link {
  font-size: 15px;
  color: #28353d;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--desktop {
    i {
      svg {
        stroke: $dark;
        transition: stroke ease-in-out $anim-speed;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &[data-expanded="true"] {
      color: $outer-space-20;

      i {
        svg {
          stroke: $outer-space-20;
        }
      }
    }
  }

  &--mobile {
    i {
      svg {
        stroke: #dfdbdb;
        transition: stroke ease-in-out $anim-speed;
      }
    }

    &:hover,
    &:focus,
    &:active,
    &[data-expanded="true"] {
      color: #A8AEB1;

      i {
        svg {
          stroke: #A8AEB1;
        }
      }
    }
  }

  &--tech {
    appearance: none;
    background: transparent;
    border: 0;
  }

  &--expand {
    appearance: none;
    background: transparent;
    border: 0;
    position: relative;
  }

  @media #{$c-md-up} {
    position: relative;

    &--mobile {
      display: none;
    }

    &--desktop {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 8px;
        bottom: -5px
      }

      +.subnav {
        transition: opacity ease-in-out $anim-speed;
        display: none !important;
      }

      &[data-expanded="false"] {
        +.subnav {
          display: flex !important;
          display: none !important;
          opacity: 0;
          left: 10000%;
          height: 0;
          width: 0;
          z-index: -1;
          transform: scale(0);
        }
      }

      &[data-expanded="true"] {
        +.subnav {
          display: flex !important;
          display: none !important;
          opacity: 1;
          height: auto;
          width: auto;
          z-index: 1;
          visibility: visible;
        }

        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    &--expand {
      span {
        margin-left: 5px;

        svg {
          stroke: $outer-space-80;
          transform: rotate(-90deg);
        }
      }
    }

    &[data-expanded="false"] {
      &:after {
        transform: none;
      }
    }

    &[data-expanded="true"] {
      &:after {
        transform: none;
      }
    }

    &:hover,
    &[data-expanded="true"] {
      color: $outer-space-20;
    }

    &.active,
    &[data-expanded="true"] {
      color: $outer-space-20;
    }

  }

  @media #{$c-md-down} {
    &--desktop {
      display: none !important;
    }

    &--mobile {
      i {
        margin-left: 10px;

        svg {
          transition: transform ease-in-out $anim-speed, stroke ease-in-out $anim-speed, fill ease-in-out $anim-speed;
        }
      }

      &[data-expanded="false"] {
        +button+.subnav {
          display: none !important;
        }
      }

      &[data-expanded="true"] {
        +button+.subnav {
          display: block !important;
        }

        i svg {
          transform: rotate(-180deg);
        }
      }
    }

    &--expand {
      span {
        width: 20px;
        height: 20px;
      }

      span svg {
        transition: transform ease-in-out $anim-speed;
        stroke: #dfdbdb;
        fill: none;
      }

      &[data-expanded="true"],
      &:hover,
      &:focus {
        +.subnav {
          display: block !important;
        }

        span svg {
          stroke: #A8AEB1;
          fill: none;
          transform: rotate(-180deg);
        }
      }

      &[data-expanded="false"] {
        +.subnav {
          display: none !important;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.navbar-toggler {
  background: none;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0px;
  padding: 0px 0px;
  padding-top: 4px;
  margin-right: 16px;
}

@media (max-width: 767px) {
  .navbar {
    padding: 15px 15px 15px 15px;

    >a {
      margin-left: 8px;
    }

    >button {
      margin-right: 8px;
    }
  }

  .navbar-nav {
    padding-top: 38px;
    padding-bottom: 120px;
    min-height: 100%;
    position: relative;
    margin-right: 0;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .navbar-expand-md .navbar-nav .nav-link.nav-link--last {
    padding-top: 16px;
    padding-bottom: 10px;
  }

  .navbar-toggler {
    margin-right: 1px;
  }


  .navbar--box-shadow {
    background-color: $dark-two;

    >a,
    >button {
      margin-bottom: 24px;
      padding-bottom: 0;
    }

    // box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);
    .navbar-brand {
      background-image: url("../images/fw-logo-sm-white.svg");
    }


  }

  a.nav-link {
    justify-content: flex-start;
  }
}



/* DARK THEME */

.o-header.o-header--dark:not(.navbar--scrolled) {
  background-color: $outer-space-60;
  & .navbar-brand-animated svg g path {
    fill: $white;
    stroke: $white;
  }
  & .hamburger-inner, & .hamburger-inner::after, & .hamburger-inner::before {
    background-color: $white;
  }
  & .o-header__dropdown-container .o-header__main-nav>ul>li>a {
      color: $white;
      &:hover {
        color: $mercury-60;
      }
    }

  @media #{$c-lg-down} {
      & .navbar-brand,
      & .navbar-brand--with-animation {
        background: url(../images/fw-logo-sm-white.svg) no-repeat;
      }
  }
}
