/* Typography */
.o-heading {
  h1 {
    font-size: 56px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -1.4px;
    color: $dark;
    margin-bottom: 32px;

    span {
      color: $grass;
    }
  }

  h2 {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.17;
    letter-spacing: -1px;
    margin-bottom: 32px;

    span {
      color: $grass;
    }
  }

  @media #{$c-md-down} {
    h1 {
      font-size: 34px;
      font-weight: 300;
      line-height: 1.18;
      letter-spacing: -0.85px;
    }

    h2 {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: -0.75px;
      margin-bottom: 26px;
    }
  }

  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.75px;
}

.o-paragraph {
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.56;
    color: $dark;
    margin-bottom: 36px;
  }

  &--lead {
    p {
      line-height: 1.4;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 18px;
    }
  }

  @media #{$c-md-down} {
    p {
      font-size: 16px;
      line-height: 1.63;
    }

    &--lead {
      p {
        font-size: 18px;
      }
    }
  }
}


@mixin heading-1 {
  font-size: 56px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.4px;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 34px;
    letter-spacing: -0.85px;
  }
}

@mixin heading-2 {
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: -1px;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -0.75px;
  }
}

@mixin heading-3 {
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.8px;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 26px;
    line-height: 1.23;
    letter-spacing: -0.65px;
  }
}

@mixin heading-4 {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.75px;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 22px;
    line-height: 1.27;
    letter-spacing: -0.55px;
  }
}

@mixin heading-5 {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44;
  }
}

@mixin heading-6 {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: $dark;
}

@mixin body-1 {
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: $dark;
  @media #{$c-md-down} {
    font-size: 16px;
    line-height: 1.63;
  }
}

@mixin body-2 {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: $dark;
}
