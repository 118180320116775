$white: #ffffff;

/* primary refers to OUTER SPACE (navy blue) */
$primary-80: #0A0D0F;
$primary-60: #1e282e;
$primary-50: #28353d;
$primary-40: #32424c;
$primary-30: #3c505c;


/* secondary refers to MERCURY (grey) */
$secondary-80: #7C868C;
$secondary-60: #a8aeb1;
$secondary-40: #dfdbdb;
$secondary-30: #ebe9e9;


/* highlight refers to SUSHI (green) */
$highlight-80: #447B20;
$highlight-70: #509025;
$highlight-60: #5ba42b;
$highlight-50: #66b830;
$highlight-40: #72cb37;



/* FORM VARS */
$form--default: #a8aeb1;
$form--active: #66b830;
$form--invalid: #b00020;


/* EXTENDS */

@mixin easing ($property, $duration, $ease) {
  -webkit-transition: $property $duration $ease;
  -moz-transition: $property $duration $ease;
  -o-transition: $property $duration $ease;
  -ms-transition: $property $duration $ease;
  transition: $property $duration $ease;
}

@mixin link-style {
    cursor: pointer;
    letter-spacing: normal;
    color: $mossy-green;
    text-decoration: underline;
    transition: color ease-in-out $anim-speed, border-color ease-in-out $anim-speed;
    &:focus, &:hover, &:active {
      color: $dark;
      text-decoration: underline;
    }
}

// replaced auto with solid as this caused an odd white outter line
@mixin custom-focus {
  outline: 2px solid rgb(153, 153, 158);
  outline: 2px solid -webkit-focus-ring-color;
}


// Custom media quesries
$c-xl-down:'(max-width: 1199px)';
$c-lg-down:'(max-width: 991px)';
$c-md-down:'(max-width: 767px)';
$c-sm-down: '(max-width: 575px)';
$c-md-up: '(min-width: 768px)';
$c-lg-up:'(min-width: 992px)';
$c-xl-up:'(min-width: 1200px)';
$anim-speed: .3s
