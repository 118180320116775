/* Buttons */
.btn {
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  font-family: Heebo, sans-serif;
  letter-spacing: -0.00625em;
  height: 36px;

  &:hover,
  &:focus,
  &:active {
    &:not(:disabled):not(.disabled) {
      box-shadow: none !important;
    }
  }

  &--primary {
    color: $white;
    background-color: $grass;
    border: none;

    &:hover {
      color: $white;
      background-color: $highlight-70;
      border: none;
    }

    &:focus {
      background-color: #447b20;
    }
  }

  &--secondary {
    color: $mossy-green;
    background-color: $primary-60;
    border: 1px solid $highlight-60;

    &:hover {
      color: $mossy-green;
      background-color: rgba(91, 164, 43, 0.05);
    }
  }

  &--dark {
    color: $white;
    background-color: $primary-50;

    &:hover {
      color: $white;
      background-color: $primary-60;
    }
  }

  &-outline-success {
    color: $mossy-green;
    border-color: $mossy-green;

    &:hover,
    &:focus,
    &:active {
      &:not(:disabled):not(.disabled) {
        background-color: #f7f6f6;
        color: $mossy-green;
        box-shadow: none;
      }
    }

    &:focus {
      &:not(:disabled):not(.disabled) {
        background-color: #ebe9e9;
      }
    }
  }

  &-light {
    color: $outer-space-30;
    background-color: $very-light-pink;
    border-color: $very-light-pink;
    &:hover, &:focus, &:active {
      background-color: $light-peach;
    }
  }
}

button.btn {
  line-height: 16px;
}

@media #{$c-sm-down} {
  .hero-banner__btn {
    width: 100%;
  }

  .hero-banner__btn--first {
    margin-bottom: 10px;
  }
}
