/* primary refers to OUTER SPACE (navy blue) */
/* secondary refers to MERCURY (grey) */
/* highlight refers to SUSHI (green) */
/* FORM VARS */
/* EXTENDS */
/* Typography */
.o-heading {
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.75px; }
  .o-heading h1 {
    font-size: 56px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -1.4px;
    color: #28353d;
    margin-bottom: 32px; }
    .o-heading h1 span {
      color: #5ba42b; }
  .o-heading h2 {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.17;
    letter-spacing: -1px;
    margin-bottom: 32px; }
    .o-heading h2 span {
      color: #5ba42b; }
  @media (max-width: 767px) {
    .o-heading h1 {
      font-size: 34px;
      font-weight: 300;
      line-height: 1.18;
      letter-spacing: -0.85px; }
    .o-heading h2 {
      font-size: 30px;
      font-weight: 300;
      line-height: 1.2;
      letter-spacing: -0.75px;
      margin-bottom: 26px; } }

.o-paragraph p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.56;
  color: #28353d;
  margin-bottom: 36px; }

.o-paragraph--lead p {
  line-height: 1.4;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 18px; }

@media (max-width: 767px) {
  .o-paragraph p {
    font-size: 16px;
    line-height: 1.63; }
  .o-paragraph--lead p {
    font-size: 18px; } }

/* Buttons */
.btn {
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  font-family: Heebo, sans-serif;
  letter-spacing: -0.00625em;
  height: 36px; }
  .btn:hover:not(:disabled):not(.disabled), .btn:focus:not(:disabled):not(.disabled), .btn:active:not(:disabled):not(.disabled) {
    box-shadow: none !important; }
  .btn--primary {
    color: #ffffff;
    background-color: #5ba42b;
    border: none; }
    .btn--primary:hover {
      color: #ffffff;
      background-color: #509025;
      border: none; }
    .btn--primary:focus {
      background-color: #447b20; }
  .btn--secondary {
    color: #447b20;
    background-color: #1e282e;
    border: 1px solid #5ba42b; }
    .btn--secondary:hover {
      color: #447b20;
      background-color: rgba(91, 164, 43, 0.05); }
  .btn--dark {
    color: #ffffff;
    background-color: #28353d; }
    .btn--dark:hover {
      color: #ffffff;
      background-color: #1e282e; }
  .btn-outline-success {
    color: #447b20;
    border-color: #447b20; }
    .btn-outline-success:hover:not(:disabled):not(.disabled), .btn-outline-success:focus:not(:disabled):not(.disabled), .btn-outline-success:active:not(:disabled):not(.disabled) {
      background-color: #f7f6f6;
      color: #447b20;
      box-shadow: none; }
    .btn-outline-success:focus:not(:disabled):not(.disabled) {
      background-color: #ebe9e9; }
  .btn-light {
    color: #3c505c;
    background-color: #ebe9e9;
    border-color: #ebe9e9; }
    .btn-light:hover, .btn-light:focus, .btn-light:active {
      background-color: #dfdbdb; }

button.btn {
  line-height: 16px; }

@media (max-width: 575px) {
  .hero-banner__btn {
    width: 100%; }
  .hero-banner__btn--first {
    margin-bottom: 10px; } }

.o-header {
  background: #ffffff;
  padding-top: 46px;
  transition: background ease-in-out 0.1s;
  z-index: 2; }
  .o-header--is-active {
    position: fixed;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    background: #1e282e; }
    .o-header--is-active .container,
    .o-header--is-active .navbar {
      height: 100%; }
  .o-header.fixed-top {
    padding-top: 80px;
    position: fixed; }
    .o-header.fixed-top .o-header-dropdown-wrapper {
      top: 54px; }
  .o-header--desktop {
    overflow: hidden;
    padding-top: 126px;
    background: #ffffff; }
    .o-header--desktop .o-header__container {
      padding-right: 15px;
      padding-left: 15px;
      max-width: 1140px;
      width: 100%;
      margin: auto;
      background: transparent; }
    .o-header--desktop .o-header__logo {
      flex: 0 0 auto;
      height: 30px;
      display: flex;
      align-items: center; }
      .o-header--desktop .o-header__logo a {
        width: 223px; }
    .o-header--desktop .o-header__cta {
      display: flex;
      flex: 0 0 auto; }
    .o-header--desktop .o-header__nav {
      flex: 1 0 auto;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
    .o-header--desktop .o-header__content {
      display: flex;
      align-items: center;
      padding-top: 46px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .o-header--desktop {
      padding-top: 98px; }
      .o-header--desktop .o-header__container {
        max-width: 720px; }
      .o-header--desktop .o-header__logo {
        width: 30px; }
      .o-header--desktop .o-header__content {
        padding-top: 18px; }
      .o-header--desktop .o-header__dropdown-container .o-header__main-nav {
        margin-right: 15px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .o-header--desktop .o-header__container {
      max-width: 960px; } }
  @media (max-width: 767px) {
    .o-header {
      padding-top: 80px; } }
  @media (max-width: 767px) {
    .o-header {
      top: 0;
      position: fixed;
      left: 0;
      right: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      transition: none; }
      .o-header--desktop {
        display: none; }
      .o-header.fixed-top {
        top: 0;
        padding-top: 8px;
        position: fixed; }
      .o-header .container {
        max-width: none;
        padding: 0; } }
  @media (min-width: 768px) {
    .o-header--mobile {
      display: none; }
    .o-header .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .o-header.fixed-top {
      position: fixed; }
      .o-header.fixed-top.fixed-top + main {
        margin-top: 98px; } }
  @media (min-width: 992px) {
    .o-header.fixed-top.fixed-top + main {
      margin-top: 126px; }
    .o-header .navbar-brand--with-animation {
      display: none; } }
  @media (min-width: 768px) {
    .o-header--is-active {
      position: relative;
      top: 0;
      height: auto;
      left: 0;
      right: 0;
      background: #ffffff; } }

/* Header */
.navbar {
  padding: 22px 0 22px 0;
  background: transparent;
  transition: none; }
  .navbar-nav {
    margin-right: 30px;
    position: relative; }
  .navbar-cta {
    display: flex; }
    .navbar-cta a:first-of-type,
    .navbar-cta button:first-of-type {
      margin-right: 12px; }
    .navbar-cta a:last-of-type,
    .navbar-cta button:last-of-type {
      padding: 7px 18px; }
  .navbar-brand {
    height: 30px;
    background: url("../images/fw-logo.svg");
    background-size: 223px 30px;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0;
    margin-right: 0px;
    flex: 1 0 223px; }
  .navbar-brand-animated {
    height: 30px;
    background-size: 223px 30px;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0;
    margin-right: 0px;
    flex: 1 0 223px;
    text-align: left; }
    .navbar-brand-animated svg {
      max-width: 223px; }
  .navbar .subnav__list {
    list-style: none;
    padding: 0;
    flex: 1 0 auto; }
  .navbar .subnav__list-item {
    margin-bottom: 28px; }
    .navbar .subnav__list-item > ul > li {
      margin-bottom: 21px;
      padding-left: 35px; }
      .navbar .subnav__list-item > ul > li:last-of-type {
        margin-bottom: 0; }
    .navbar .subnav__list-item button,
    .navbar .subnav__list-item a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: color ease-in-out 0.3s; }
      .navbar .subnav__list-item button i svg,
      .navbar .subnav__list-item a i svg {
        fill: #dfdbdb;
        transition: fill ease-in-out 0.3s, stroke ease-in-out 0.3s;
        flex: 0 0 auto; }
        .navbar .subnav__list-item button i svg.subnav__list-item-wurks, .navbar .subnav__list-item button i svg.subnav__list-item-design,
        .navbar .subnav__list-item a i svg.subnav__list-item-wurks,
        .navbar .subnav__list-item a i svg.subnav__list-item-design {
          stroke: #DFDBDB; }
    .navbar .subnav__list-item .subnav {
      display: flex;
      flex-direction: column; }
      .navbar .subnav__list-item .subnav__list-item {
        display: flex; }
    .navbar .subnav__list-item i {
      width: 23px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px; }
    .navbar .subnav__list-item--tech > .subnav {
      margin-top: 20px;
      margin-bottom: 15px; }
    .navbar .subnav__list-item--tech > a,
    .navbar .subnav__list-item--tech > button {
      position: relative;
      padding-left: 0;
      padding-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .navbar .subnav__list-item--tech > a i,
      .navbar .subnav__list-item--tech > button i {
        width: 23px;
        height: 23px; }
      .navbar .subnav__list-item--tech > a:after,
      .navbar .subnav__list-item--tech > button:after {
        content: "";
        position: absolute;
        right: -17px;
        background: url(../images/icons/icon-arrow-small.svg) no-repeat center;
        width: 20px;
        height: 20px;
        transition: transform ease-in-out 0.3s, background ease-in-out 0.3s; }
      .navbar .subnav__list-item--tech > a:hover:after, .navbar .subnav__list-item--tech > a:active:after, .navbar .subnav__list-item--tech > a:focus:after,
      .navbar .subnav__list-item--tech > button:hover:after,
      .navbar .subnav__list-item--tech > button:active:after,
      .navbar .subnav__list-item--tech > button:focus:after {
        background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center; }
      .navbar .subnav__list-item--tech > a[data-expanded="true"],
      .navbar .subnav__list-item--tech > button[data-expanded="true"] {
        color: #A8AEB1; }
        .navbar .subnav__list-item--tech > a[data-expanded="true"]:after,
        .navbar .subnav__list-item--tech > button[data-expanded="true"]:after {
          background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
          transform: rotate(-180deg); }
        .navbar .subnav__list-item--tech > a[data-expanded="true"] i svg,
        .navbar .subnav__list-item--tech > button[data-expanded="true"] i svg {
          fill: #A8AEB1; }
  .navbar .subnav .subnav {
    margin-top: 20px;
    position: relative; }
    .navbar .subnav .subnav:after {
      content: "";
      position: absolute;
      width: 1px;
      top: -12px;
      bottom: -6px;
      left: 9px;
      background: #32424C; }
  .navbar-collapse.collapsing {
    transition: none; }
  @media (max-width: 991px) {
    .navbar-brand, .navbar-brand--with-animation {
      background: url("../images/fw-logo-sm.svg") no-repeat;
      flex: 0 0 30px;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left center;
      padding: 0px 0px 0px 0px;
      margin-right: 0px; }
      .navbar-brand svg, .navbar-brand--with-animation svg {
        display: none; }
    .navbar-brand-animated {
      display: none; } }
  @media (min-width: 768px) {
    .navbar .subnav {
      position: absolute;
      box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
      border: solid 1px #d3cece;
      background-color: #fcfcfc;
      list-style: none;
      min-width: 206px;
      border-radius: 4px;
      top: 42px;
      left: calc(50% - 100px);
      display: flex; }
      .navbar .subnav:after {
        content: "";
        position: absolute;
        left: 50%;
        top: -9px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid white;
        transform: rotate(90deg); }
      .navbar .subnav:before {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        top: -12px;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #d3cece;
        transform: rotate(90deg); }
      .navbar .subnav__list {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 24px;
        padding-right: 0;
        min-width: 206px; }
        .navbar .subnav__list li {
          padding-right: 24px;
          margin-bottom: 0; }
      .navbar .subnav--why:after {
        left: calc(206px / 2); }
      .navbar .subnav--why:before {
        left: calc(206px / 2 - 1px); }
      .navbar .subnav__list-tech {
        min-width: 206px;
        padding-left: 10px; }
        .navbar .subnav__list-tech[data-hidden="true"] {
          display: none; }
      .navbar .subnav__list-item a,
      .navbar .subnav__list-item button {
        color: #28353d;
        font-size: 13px;
        padding-top: 14px;
        padding-bottom: 14px; }
        .navbar .subnav__list-item a i,
        .navbar .subnav__list-item button i {
          margin-right: 13px; }
          .navbar .subnav__list-item a i svg,
          .navbar .subnav__list-item button i svg {
            fill: #28353d; }
            .navbar .subnav__list-item a i svg.subnav__list-item-wurks,
            .navbar .subnav__list-item button i svg.subnav__list-item-wurks {
              stroke: #28353d; }
            .navbar .subnav__list-item a i svg.subnav__list-item-design,
            .navbar .subnav__list-item button i svg.subnav__list-item-design {
              stroke: #28353d;
              fill: #ffffff; }
        .navbar .subnav__list-item a:focus, .navbar .subnav__list-item a:active, .navbar .subnav__list-item a:hover,
        .navbar .subnav__list-item button:focus,
        .navbar .subnav__list-item button:active,
        .navbar .subnav__list-item button:hover {
          text-decoration: none;
          color: #53626b; }
          .navbar .subnav__list-item a:focus i svg, .navbar .subnav__list-item a:active i svg, .navbar .subnav__list-item a:hover i svg,
          .navbar .subnav__list-item button:focus i svg,
          .navbar .subnav__list-item button:active i svg,
          .navbar .subnav__list-item button:hover i svg {
            fill: #53626b; }
            .navbar .subnav__list-item a:focus i svg.subnav__list-item-wurks, .navbar .subnav__list-item a:active i svg.subnav__list-item-wurks, .navbar .subnav__list-item a:hover i svg.subnav__list-item-wurks,
            .navbar .subnav__list-item button:focus i svg.subnav__list-item-wurks,
            .navbar .subnav__list-item button:active i svg.subnav__list-item-wurks,
            .navbar .subnav__list-item button:hover i svg.subnav__list-item-wurks {
              stroke: #53626b; }
            .navbar .subnav__list-item a:focus i svg.subnav__list-item-design, .navbar .subnav__list-item a:active i svg.subnav__list-item-design, .navbar .subnav__list-item a:hover i svg.subnav__list-item-design,
            .navbar .subnav__list-item button:focus i svg.subnav__list-item-design,
            .navbar .subnav__list-item button:active i svg.subnav__list-item-design,
            .navbar .subnav__list-item button:hover i svg.subnav__list-item-design {
              stroke: #53626b;
              fill: #ffffff; }
      .navbar .subnav__list-item:last-of-type {
        margin-bottom: 0; }
      .navbar .subnav .subnav__list-item--tech {
        display: flex; }
        .navbar .subnav .subnav__list-item--tech button {
          width: 100%;
          justify-content: flex-start; }
          .navbar .subnav .subnav__list-item--tech button::after {
            transform: rotate(-90deg); }
          .navbar .subnav .subnav__list-item--tech button:after {
            content: "";
            position: absolute;
            right: -17px;
            background: url(../images/icons/icon-arrow-small-dark.svg) no-repeat center;
            width: 20px;
            height: 20px;
            transition: transform ease-in-out 0.3s, background ease-in-out 0.3s; }
          .navbar .subnav .subnav__list-item--tech button:hover:after, .navbar .subnav .subnav__list-item--tech button:active:after, .navbar .subnav .subnav__list-item--tech button:focus:after {
            background: url(../images/icons/icon-arrow-small-dark-hover.svg) no-repeat center; }
        .navbar .subnav .subnav__list-item--tech ul {
          display: none; }
      .navbar .subnav__list-item-optim--mobile {
        display: none; } }
  @media (max-width: 767px) {
    .navbar {
      padding: 24px 15px 24px 15px; }
      .navbar--desktop {
        display: none; }
      .navbar .subnav__list-item-optim--desktop {
        display: none; }
      .navbar .subnav__list-tech {
        display: none; }
      .navbar .subnav a:focus, .navbar .subnav a:active, .navbar .subnav a:hover,
      .navbar .subnav button:focus,
      .navbar .subnav button:active,
      .navbar .subnav button:hover {
        text-decoration: none;
        color: #A8AEB1; }
        .navbar .subnav a:focus i svg, .navbar .subnav a:active i svg, .navbar .subnav a:hover i svg,
        .navbar .subnav button:focus i svg,
        .navbar .subnav button:active i svg,
        .navbar .subnav button:hover i svg {
          fill: #A8AEB1; }
          .navbar .subnav a:focus i svg.subnav__list-item-wurks, .navbar .subnav a:active i svg.subnav__list-item-wurks, .navbar .subnav a:hover i svg.subnav__list-item-wurks,
          .navbar .subnav button:focus i svg.subnav__list-item-wurks,
          .navbar .subnav button:active i svg.subnav__list-item-wurks,
          .navbar .subnav button:hover i svg.subnav__list-item-wurks {
            stroke: #A8AEB1; }
          .navbar .subnav a:focus i svg.subnav__list-item-design, .navbar .subnav a:active i svg.subnav__list-item-design, .navbar .subnav a:hover i svg.subnav__list-item-design,
          .navbar .subnav button:focus i svg.subnav__list-item-design,
          .navbar .subnav button:active i svg.subnav__list-item-design,
          .navbar .subnav button:hover i svg.subnav__list-item-design {
            stroke: #A8AEB1;
            fill: #28353d; }
      .navbar .subnav a i svg.subnav__list-item-design,
      .navbar .subnav button i svg.subnav__list-item-design {
        stroke: #dfdbdb;
        fill: #28353d; }
      .navbar .nav-link {
        color: #ffffff;
        font-size: 26px;
        padding-right: 30px;
        margin-bottom: 0;
        font-weight: 300;
        letter-spacing: -0.65px; }
        .navbar .nav-link i {
          display: flex; }
        .navbar .nav-link:hover:after {
          background-image: url("../images/icons/arrow-large-hover.svg"); }
      .navbar .nav-item ul {
        padding-left: 0;
        margin-top: 10px; }
      .navbar-collapse {
        overflow-x: scroll;
        height: calc(100% - 16px);
        margin-left: -16px;
        margin-right: -16px;
        padding: 0 16px; }
      .navbar-cta {
        display: none; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .navbar-nav {
      margin-right: 15px; } }

.navbar--scrolled {
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1); }
  .navbar--scrolled .o-header__content {
    padding-top: 0; }

.nav-item {
  position: relative; }
  .nav-item ul li a,
  .nav-item ul button {
    color: #dfdbdb;
    font-size: 14px; }

.nav-link {
  font-size: 15px;
  color: #28353d;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center; }
  .nav-link i {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .nav-link--desktop i svg {
    stroke: #28353d;
    transition: stroke ease-in-out 0.3s; }
  .nav-link--desktop:hover, .nav-link--desktop:focus, .nav-link--desktop:active, .nav-link--desktop[data-expanded="true"] {
    color: #53626b; }
    .nav-link--desktop:hover i svg, .nav-link--desktop:focus i svg, .nav-link--desktop:active i svg, .nav-link--desktop[data-expanded="true"] i svg {
      stroke: #53626b; }
  .nav-link--mobile i svg {
    stroke: #dfdbdb;
    transition: stroke ease-in-out 0.3s; }
  .nav-link--mobile:hover, .nav-link--mobile:focus, .nav-link--mobile:active, .nav-link--mobile[data-expanded="true"] {
    color: #A8AEB1; }
    .nav-link--mobile:hover i svg, .nav-link--mobile:focus i svg, .nav-link--mobile:active i svg, .nav-link--mobile[data-expanded="true"] i svg {
      stroke: #A8AEB1; }
  .nav-link--tech {
    appearance: none;
    background: transparent;
    border: 0; }
  .nav-link--expand {
    appearance: none;
    background: transparent;
    border: 0;
    position: relative; }
  @media (min-width: 768px) {
    .nav-link {
      position: relative; }
      .nav-link--mobile {
        display: none; }
      .nav-link--desktop {
        position: relative; }
        .nav-link--desktop:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 8px;
          bottom: -5px; }
        .nav-link--desktop + .subnav {
          transition: opacity ease-in-out 0.3s;
          display: none !important; }
        .nav-link--desktop[data-expanded="false"] + .subnav {
          display: flex !important;
          display: none !important;
          opacity: 0;
          left: 10000%;
          height: 0;
          width: 0;
          z-index: -1;
          transform: scale(0); }
        .nav-link--desktop[data-expanded="true"] + .subnav {
          display: flex !important;
          display: none !important;
          opacity: 1;
          height: auto;
          width: auto;
          z-index: 1;
          visibility: visible; }
        .nav-link--desktop[data-expanded="true"]:after {
          transform: rotate(-180deg); }
      .nav-link--expand span {
        margin-left: 5px; }
        .nav-link--expand span svg {
          stroke: #0a0d0f;
          transform: rotate(-90deg); }
      .nav-link[data-expanded="false"]:after {
        transform: none; }
      .nav-link[data-expanded="true"]:after {
        transform: none; }
      .nav-link:hover, .nav-link[data-expanded="true"] {
        color: #53626b; }
      .nav-link.active, .nav-link[data-expanded="true"] {
        color: #53626b; } }
  @media (max-width: 767px) {
    .nav-link--desktop {
      display: none !important; }
    .nav-link--mobile i {
      margin-left: 10px; }
      .nav-link--mobile i svg {
        transition: transform ease-in-out 0.3s, stroke ease-in-out 0.3s, fill ease-in-out 0.3s; }
    .nav-link--mobile[data-expanded="false"] + button + .subnav {
      display: none !important; }
    .nav-link--mobile[data-expanded="true"] + button + .subnav {
      display: block !important; }
    .nav-link--mobile[data-expanded="true"] i svg {
      transform: rotate(-180deg); }
    .nav-link--expand span {
      width: 20px;
      height: 20px; }
    .nav-link--expand span svg {
      transition: transform ease-in-out 0.3s;
      stroke: #dfdbdb;
      fill: none; }
    .nav-link--expand[data-expanded="true"] + .subnav, .nav-link--expand:hover + .subnav, .nav-link--expand:focus + .subnav {
      display: block !important; }
    .nav-link--expand[data-expanded="true"] span svg, .nav-link--expand:hover span svg, .nav-link--expand:focus span svg {
      stroke: #A8AEB1;
      fill: none;
      transform: rotate(-180deg); }
    .nav-link--expand[data-expanded="false"] + .subnav {
      display: none !important; } }

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 7px;
    padding-bottom: 7px; } }

.navbar-toggler {
  background: none;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 0px;
  padding: 0px 0px;
  padding-top: 4px;
  margin-right: 16px; }

@media (max-width: 767px) {
  .navbar {
    padding: 15px 15px 15px 15px; }
    .navbar > a {
      margin-left: 8px; }
    .navbar > button {
      margin-right: 8px; }
  .navbar-nav {
    padding-top: 38px;
    padding-bottom: 120px;
    min-height: 100%;
    position: relative;
    margin-right: 0; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 14px;
    padding-bottom: 14px; }
  .navbar-expand-md .navbar-nav .nav-link.nav-link--last {
    padding-top: 16px;
    padding-bottom: 10px; }
  .navbar-toggler {
    margin-right: 1px; }
  .navbar--box-shadow {
    background-color: #1e282e; }
    .navbar--box-shadow > a,
    .navbar--box-shadow > button {
      margin-bottom: 24px;
      padding-bottom: 0; }
    .navbar--box-shadow .navbar-brand {
      background-image: url("../images/fw-logo-sm-white.svg"); }
  a.nav-link {
    justify-content: flex-start; } }

/* DARK THEME */
.o-header.o-header--dark:not(.navbar--scrolled) {
  background-color: #1e282e; }
  .o-header.o-header--dark:not(.navbar--scrolled) .navbar-brand-animated svg g path {
    fill: #ffffff;
    stroke: #ffffff; }
  .o-header.o-header--dark:not(.navbar--scrolled) .hamburger-inner, .o-header.o-header--dark:not(.navbar--scrolled) .hamburger-inner::after, .o-header.o-header--dark:not(.navbar--scrolled) .hamburger-inner::before {
    background-color: #ffffff; }
  .o-header.o-header--dark:not(.navbar--scrolled) .o-header__dropdown-container .o-header__main-nav > ul > li > a {
    color: #ffffff; }
    .o-header.o-header--dark:not(.navbar--scrolled) .o-header__dropdown-container .o-header__main-nav > ul > li > a:hover {
      color: #a8aeb1; }
  @media (max-width: 991px) {
    .o-header.o-header--dark:not(.navbar--scrolled) .navbar-brand,
    .o-header.o-header--dark:not(.navbar--scrolled) .navbar-brand--with-animation {
      background: url(../images/fw-logo-sm-white.svg) no-repeat; } }

.hamburger {
  height: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none !important; }

.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 1; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #ffffff; }

.hamburger-box {
  width: 26px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 1px; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 26px;
  height: 2px;
  background-color: #28353d;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -8px; }

.hamburger-inner::after {
  bottom: -8px; }

/* Squeeze */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.2s ease, opacity 0.075s ease; }

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.2s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #ffffff; }

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.2s ease;
  background-color: #ffffff; }

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #ffffff; }

.nav-dropdown-menu {
  display: flex;
  position: absolute;
  transform-origin: left top;
  background-color: white;
  left: 0;
  height: 0;
  width: 0;
  will-change: transform, height, width;
  animation: 0.3s ease 0s 1 normal forwards running FlChO;
  box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
  border: solid 1px #d3cece;
  background-color: #fcfcfc;
  border-radius: 4px;
  top: 42px; }
  .nav-dropdown-menu:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -9px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
    transform: rotate(90deg); }
  .nav-dropdown-menu:before {
    content: "";
    position: absolute;
    left: calc(50% - 1px);
    top: -12px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #d3cece;
    transform: rotate(90deg); }
  .nav-dropdown-menu[aria-hidden="false"] {
    width: 414px;
    height: 287px;
    transform: translateX(-43px);
    animation: 0.3s ease 0s 1 normal forwards running test;
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 24px;
    padding-right: 0; }
  .nav-dropdown-menu[aria-hidden="true"] {
    padding: 0; }
    .nav-dropdown-menu[aria-hidden="true"]:after, .nav-dropdown-menu[aria-hidden="true"]:before {
      display: none; }
    .nav-dropdown-menu[aria-hidden="true"] ul {
      display: none; }
  .nav-dropdown-menu__list {
    list-style: none;
    padding: 0;
    flex: 1 0 auto; }
  .nav-dropdown-menu__list-item {
    margin-bottom: 28px; }
    .nav-dropdown-menu__list-item > ul > li {
      margin-bottom: 21px;
      padding-left: 35px; }
      .nav-dropdown-menu__list-item > ul > li:last-of-type {
        margin-bottom: 0; }
    .nav-dropdown-menu__list-item button,
    .nav-dropdown-menu__list-item a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: color ease-in-out 0.3s;
      color: #28353d;
      font-size: 13px;
      padding-top: 14px;
      padding-bottom: 14px; }
    .nav-dropdown-menu__list-item a,
    .nav-dropdown-menu__list-item button {
      color: #28353d;
      font-size: 13px;
      padding-top: 14px;
      padding-bottom: 14px; }
      .nav-dropdown-menu__list-item a i,
      .nav-dropdown-menu__list-item button i {
        margin-right: 13px; }
        .nav-dropdown-menu__list-item a i svg,
        .nav-dropdown-menu__list-item button i svg {
          fill: #28353d; }
          .nav-dropdown-menu__list-item a i svg.nav-dropdown-menu__list-item-wurks,
          .nav-dropdown-menu__list-item button i svg.nav-dropdown-menu__list-item-wurks {
            stroke: #28353d; }
          .nav-dropdown-menu__list-item a i svg.nav-dropdown-menu__list-item-design,
          .nav-dropdown-menu__list-item button i svg.nav-dropdown-menu__list-item-design {
            stroke: #28353d;
            fill: #ffffff; }
      .nav-dropdown-menu__list-item a:focus, .nav-dropdown-menu__list-item a:active, .nav-dropdown-menu__list-item a:hover,
      .nav-dropdown-menu__list-item button:focus,
      .nav-dropdown-menu__list-item button:active,
      .nav-dropdown-menu__list-item button:hover {
        text-decoration: none;
        color: #53626b; }
        .nav-dropdown-menu__list-item a:focus i svg, .nav-dropdown-menu__list-item a:active i svg, .nav-dropdown-menu__list-item a:hover i svg,
        .nav-dropdown-menu__list-item button:focus i svg,
        .nav-dropdown-menu__list-item button:active i svg,
        .nav-dropdown-menu__list-item button:hover i svg {
          fill: #53626b; }
          .nav-dropdown-menu__list-item a:focus i svg.nav-dropdown-menu__list-item-wurks, .nav-dropdown-menu__list-item a:active i svg.nav-dropdown-menu__list-item-wurks, .nav-dropdown-menu__list-item a:hover i svg.nav-dropdown-menu__list-item-wurks,
          .nav-dropdown-menu__list-item button:focus i svg.nav-dropdown-menu__list-item-wurks,
          .nav-dropdown-menu__list-item button:active i svg.nav-dropdown-menu__list-item-wurks,
          .nav-dropdown-menu__list-item button:hover i svg.nav-dropdown-menu__list-item-wurks {
            stroke: #53626b; }
          .nav-dropdown-menu__list-item a:focus i svg.nav-dropdown-menu__list-item-design, .nav-dropdown-menu__list-item a:active i svg.nav-dropdown-menu__list-item-design, .nav-dropdown-menu__list-item a:hover i svg.nav-dropdown-menu__list-item-design,
          .nav-dropdown-menu__list-item button:focus i svg.nav-dropdown-menu__list-item-design,
          .nav-dropdown-menu__list-item button:active i svg.nav-dropdown-menu__list-item-design,
          .nav-dropdown-menu__list-item button:hover i svg.nav-dropdown-menu__list-item-design {
            stroke: #53626b;
            fill: #ffffff; }
    .nav-dropdown-menu__list-item .subnav {
      display: flex;
      flex-direction: column; }
      .nav-dropdown-menu__list-item .subnav__list-item {
        display: flex; }
    .nav-dropdown-menu__list-item i {
      width: 23px;
      height: 23px;
      min-width: 23px;
      min-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      flex: 0 0 auto; }
    .nav-dropdown-menu__list-item--tech > .subnav {
      margin-top: 20px;
      margin-bottom: 15px; }
    .nav-dropdown-menu__list-item--tech > a,
    .nav-dropdown-menu__list-item--tech > button {
      position: relative;
      padding-left: 0;
      padding-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .nav-dropdown-menu__list-item--tech > a i,
      .nav-dropdown-menu__list-item--tech > button i {
        width: 23px;
        height: 23px; }
      .nav-dropdown-menu__list-item--tech > a:after,
      .nav-dropdown-menu__list-item--tech > button:after {
        content: "";
        position: absolute;
        right: -17px;
        background: url(../images/icons/icon-arrow-small.svg) no-repeat center;
        width: 20px;
        height: 20px;
        transition: transform ease-in-out 0.3s, background ease-in-out 0.3s; }
      .nav-dropdown-menu__list-item--tech > a:hover:after, .nav-dropdown-menu__list-item--tech > a:active:after, .nav-dropdown-menu__list-item--tech > a:focus:after,
      .nav-dropdown-menu__list-item--tech > button:hover:after,
      .nav-dropdown-menu__list-item--tech > button:active:after,
      .nav-dropdown-menu__list-item--tech > button:focus:after {
        background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center; }
      .nav-dropdown-menu__list-item--tech > a[data-expanded="true"],
      .nav-dropdown-menu__list-item--tech > button[data-expanded="true"] {
        color: #A8AEB1; }
        .nav-dropdown-menu__list-item--tech > a[data-expanded="true"]:after,
        .nav-dropdown-menu__list-item--tech > button[data-expanded="true"]:after {
          background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
          transform: rotate(-180deg); }
        .nav-dropdown-menu__list-item--tech > a[data-expanded="true"] i svg,
        .nav-dropdown-menu__list-item--tech > button[data-expanded="true"] i svg {
          fill: #A8AEB1; }
  .nav-dropdown-menu .subnav {
    margin-top: 20px;
    position: relative; }
    .nav-dropdown-menu .subnav:after {
      content: "";
      position: absolute;
      width: 1px;
      top: -12px;
      bottom: -6px;
      left: 9px;
      background: #32424C; }
  .nav-dropdown-menu ul {
    transition: opacity ease-in .3s;
    opacity: 1;
    min-width: 206px; }
    .nav-dropdown-menu ul li {
      padding-right: 24px;
      margin-bottom: 0; }
  .nav-dropdown-menu ul[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
    position: absolute; }

@keyframes FlChO {
  0% {
    transform: translateX(0) rotateX(0deg);
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes test {
  0% {
    transform: translateX(-43px) rotateX(-20deg);
    opacity: 0; }
  100% {
    opacity: 1; } }

.o-header-dropdown-wrapper__content {
  perspective: 1000px;
  transition: visibility 0.3s, opacity 0.3s;
  visibility: hidden;
  border-radius: 4px; }

.o-header-dropdown-triangle {
  transform: translateZ(0);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
  position: relative;
  z-index: 2;
  pointer-events: none; }
  .o-header-dropdown-triangle__image {
    width: 12px;
    height: 8px;
    background: url(../images/icons/dropdown-triangle.svg) no-repeat center;
    margin: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
    position: relative;
    top: 1px;
    z-index: 2; }

.o-header__dropdown-container {
  position: relative;
  height: 126px;
  background-color: #fcfcfc;
  z-index: 10000; }
  .o-header__dropdown-container::before {
    /* never visible - used in JS to check mq */
    content: 'mobile';
    display: none; }
  .o-header__dropdown-container .o-header__main-nav {
    display: none; }
  .o-header__dropdown-container .o-header-dropdown-wrapper {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 1.2em 5%; }
  .o-header__dropdown-container.nav-open .o-header-dropdown-wrapper {
    display: block; }
  .o-header__dropdown-container .o-header-dropdown-wrapper__dropdown-list > ul > li {
    margin-bottom: 3.3em; }
  .o-header__dropdown-container .links .content > ul > li {
    margin-top: 1em; }
  .o-header__dropdown-container::before {
    content: 'desktop'; }
  .o-header__dropdown-container .nav-trigger {
    display: none; }
  .o-header__dropdown-container .o-header__main-nav {
    display: flex;
    margin-right: 30px; }
    .o-header__dropdown-container .o-header__main-nav ul {
      margin: 0; }
  .o-header__dropdown-container .o-header__main-nav > ul > li {
    display: inline-block;
    float: left; }
  .o-header__dropdown-container .o-header__main-nav > ul > li > a {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 15px;
    line-height: 1;
    color: #28353d;
    transition: color ease-in-out 0.3s;
    cursor: pointer; }
    .o-header__dropdown-container .o-header__main-nav > ul > li > a:hover {
      text-decoration: none;
      color: #939a9e; }
      .o-header__dropdown-container .o-header__main-nav > ul > li > a:hover svg {
        stroke: #939a9e; }
    .o-header__dropdown-container .o-header__main-nav > ul > li > a i {
      width: 20px;
      height: 20px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .o-header__dropdown-container .o-header__main-nav > ul > li > a i svg {
        stroke: #28353d;
        transition: stroke ease-in-out 0.3s; }
  .o-header__dropdown-container.is-dropdown-visible .o-header__main-nav > ul > li > a {
    /* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
    opacity: 1; }
  .o-header__dropdown-container.is-dropdown-visible .o-header__main-nav > ul > li.active > a {
    opacity: 1; }
  .o-header__dropdown-container .o-header-dropdown-wrapper {
    /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
    display: block;
    top: 100px;
    width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    /* Force Hardware acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform 0.3s; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper {
    transform: translateY(0); }
  .o-header__dropdown-container .o-header-dropdown-wrapper__dropdown-list {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    transform: rotateX(-15deg);
    will-change: transform, width, height;
    transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
    opacity: 0;
    transform-origin: top center; }
    .no-csstransitions .o-header__dropdown-container .o-header-dropdown-wrapper__dropdown-list {
      display: none; }
    .o-header__dropdown-container .o-header-dropdown-wrapper__dropdown-list:after {
      border: solid 1px #d3cece;
      box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      content: '';
      position: absolute;
      width: calc(100% + 6px);
      height: calc(100% + 1px);
      top: 0px;
      left: -2px;
      top: 0;
      background: #fcfcfc; }
    .o-header__dropdown-container .o-header-dropdown-wrapper__dropdown-list > ul {
      position: relative;
      z-index: 1;
      height: 100%;
      width: 100%;
      overflow: hidden; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper__dropdown-list {
    visibility: visible;
    transition: transform 0.3s, width 0.3s, height 0.3s, opacity 0.3s;
    opacity: 1;
    transform: rotateX(0); }
    .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper__dropdown-list::before {
      opacity: 1; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper__content {
    visibility: visible;
    transition: transform 0.3s, width 0.3s, height 0.3s, opacity 0.3s;
    opacity: 1; }
    .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper__content::before {
      opacity: 1; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-triangle__image {
    visibility: visible;
    transition: transform 0.3s, width 0.3s, height 0.3s, opacity 0.3s;
    opacity: 1; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-triangle {
    visibility: visible;
    transition: transform 0.3s, width 0.3s, height 0.3s, opacity 0.3s;
    opacity: 1; }
  .o-header__dropdown-container .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transition: opacity 0.3s, visibility 0.3s; }
    .o-header__dropdown-container .dropdown.active {
      opacity: 1;
      visibility: visible; }
    .o-header__dropdown-container .dropdown.move-left .content {
      transform: translateX(-100px); }
    .o-header__dropdown-container .dropdown.move-right .content {
      transform: translateX(100px); }
  .o-header__dropdown-container .label {
    /* hide the label on bigger devices */
    display: none; }
  .o-header__dropdown-container .content {
    padding: 16px 20px 20px 24px;
    transition: transform 0.3s;
    text-align: left; }
    .o-header__dropdown-container .content > ul {
      padding: 0; }
    .o-header__dropdown-container .content > ul > li {
      display: block;
      width: 100%;
      margin-right: 4%;
      margin-top: 0; }
      .o-header__dropdown-container .content > ul > li a {
        display: flex;
        align-items: center;
        text-decoration: none;
        -webkit-transition: color ease-in-out 0.3s;
        transition: color ease-in-out 0.3s;
        font-size: 13px;
        line-height: 1;
        color: #28353d;
        text-decoration: none;
        padding-top: 14px;
        padding-bottom: 14px;
        position: relative; }
        .o-header__dropdown-container .content > ul > li a span {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -17px;
          display: flex;
          align-items: center; }
          .o-header__dropdown-container .content > ul > li a span svg {
            stroke: #28353d;
            transform: rotate(-90deg);
            transition: transform ease-in-out 0.3s, stroke ease-in-out 0.3s, fill ease-in-out 0.3s; }
        .o-header__dropdown-container .content > ul > li a i {
          width: 23px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px; }
          .o-header__dropdown-container .content > ul > li a i svg {
            transition: transform ease-in-out 0.3s, stroke ease-in-out 0.3s, fill ease-in-out 0.3s; }
            .o-header__dropdown-container .content > ul > li a i svg.icon-fw, .o-header__dropdown-container .content > ul > li a i svg.icon-design {
              stroke: #28353d; }
            .o-header__dropdown-container .content > ul > li a i svg.icon-design {
              stroke: #28353d;
              fill: #ffffff; }
        .o-header__dropdown-container .content > ul > li a:focus, .o-header__dropdown-container .content > ul > li a:active, .o-header__dropdown-container .content > ul > li a:hover {
          text-decoration: none;
          color: #53626b; }
          .o-header__dropdown-container .content > ul > li a:focus span svg, .o-header__dropdown-container .content > ul > li a:active span svg, .o-header__dropdown-container .content > ul > li a:hover span svg {
            stroke: #53626b; }
          .o-header__dropdown-container .content > ul > li a:focus i svg,
          .o-header__dropdown-container .content > ul > li a:focus span svg, .o-header__dropdown-container .content > ul > li a:active i svg,
          .o-header__dropdown-container .content > ul > li a:active span svg, .o-header__dropdown-container .content > ul > li a:hover i svg,
          .o-header__dropdown-container .content > ul > li a:hover span svg {
            fill: #53626b; }
            .o-header__dropdown-container .content > ul > li a:focus i svg.icon-fw,
            .o-header__dropdown-container .content > ul > li a:focus span svg.icon-fw, .o-header__dropdown-container .content > ul > li a:active i svg.icon-fw,
            .o-header__dropdown-container .content > ul > li a:active span svg.icon-fw, .o-header__dropdown-container .content > ul > li a:hover i svg.icon-fw,
            .o-header__dropdown-container .content > ul > li a:hover span svg.icon-fw {
              stroke: #53626b; }
            .o-header__dropdown-container .content > ul > li a:focus i svg.icon-design,
            .o-header__dropdown-container .content > ul > li a:focus span svg.icon-design, .o-header__dropdown-container .content > ul > li a:active i svg.icon-design,
            .o-header__dropdown-container .content > ul > li a:active span svg.icon-design, .o-header__dropdown-container .content > ul > li a:hover i svg.icon-design,
            .o-header__dropdown-container .content > ul > li a:hover span svg.icon-design {
              stroke: #53626b;
              fill: #ffffff; }
            .o-header__dropdown-container .content > ul > li a:focus i svg #icon-arrow-small,
            .o-header__dropdown-container .content > ul > li a:focus span svg #icon-arrow-small, .o-header__dropdown-container .content > ul > li a:active i svg #icon-arrow-small,
            .o-header__dropdown-container .content > ul > li a:active span svg #icon-arrow-small, .o-header__dropdown-container .content > ul > li a:hover i svg #icon-arrow-small,
            .o-header__dropdown-container .content > ul > li a:hover span svg #icon-arrow-small {
              stroke: #53626b; }
      .o-header__dropdown-container .content > ul > li:nth-of-type(2n) {
        margin-right: 0; }
  .o-header__dropdown-container .links .content > ul > li {
    margin-top: 0; }
  .o-header__dropdown-container .links .content,
  .o-header__dropdown-container .button .content {
    width: 390px; }
  .o-header__dropdown-container .o-header-dropdown-wrapper .why {
    width: 380px; }
    .o-header__dropdown-container .o-header-dropdown-wrapper .why .content {
      display: flex; }
      .o-header__dropdown-container .o-header-dropdown-wrapper .why .content ul {
        margin-right: 34px;
        width: 46%; }
        .o-header__dropdown-container .o-header-dropdown-wrapper .why .content ul:last-of-type {
          margin-right: 0;
          width: 55%; }
  .o-header__dropdown-container .o-header-dropdown-wrapper .solutions {
    width: 210px; }
  .o-header__dropdown-container .o-header-dropdown-wrapper .company {
    width: 210px; }
  .o-header__dropdown-container .o-header-dropdown-wrapper__bg-layer {
    /* morph dropdown background */
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    background-color: #fcfcfc;
    opacity: 0;
    transition: opacity 0.3s;
    transform-origin: top left;
    transform: translateZ(0);
    will-change: transform;
    backface-visibility: hidden; }
  .o-header__dropdown-container.is-dropdown-visible .o-header-dropdown-wrapper__bg-layer {
    opacity: 1;
    transition: transform 0.3s, opacity 0.3s; }
  @media (max-width: 991px) and (min-width: 768px) {
    .o-header__dropdown-container .o-header-dropdown-wrapper {
      top: 78px; } }
  @media (max-width: 991px) {
    .o-header__dropdown-container {
      position: absolute;
      height: 126px;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0;
      text-align: center;
      background-color: transparent; } }
  @media (min-width: 992px) {
    .o-header__dropdown-container {
      position: absolute;
      height: 126px;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0;
      text-align: center;
      background-color: transparent; } }

/* Footer */
.o-footer {
  padding: 100px 0;
  background-color: #1e282e; }
  .o-footer-container {
    height: auto;
    padding: 20px 0px 50px 0px;
    background: #1e282e;
    border-top: 5px solid #5ba42b;
    z-index: 1; }
  .o-footer__legal {
    font-size: 12px;
    line-height: 1.33;
    color: #ffffff;
    text-align: right;
    display: flex;
    align-items: flex-end; }
    .o-footer__legal p {
      margin: 0; }
  .o-footer h4 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63; }
  .o-footer__links {
    margin-bottom: 84px; }
    .o-footer__links ul {
      color: #ffffff;
      list-style: none;
      padding: 0; }
      .o-footer__links ul li a {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: -0.35px;
        color: #a8aeb1;
        text-decoration: none;
        transition: color ease-in-out 0.3s; }
        .o-footer__links ul li a:hover {
          color: #fcfcfc; }
    .o-footer__links .col.o-footer__links-tools {
      flex: 0 1 160px; }
    .o-footer__links .col.o-footer__links-features {
      flex: 0 1 220px; }
    .o-footer__links .col.o-footer__links-services {
      flex: 0 1 160px; }
    .o-footer__links .col.o-footer__links-resources {
      flex: 0 1 160px; }
    .o-footer__links .col.o-footer__links-company {
      flex: 0 1 160px; }
    .o-footer__links span {
      color: #5ba42b;
      display: inline;
      margin: 0px 10px 0px 10px; }
  @media (max-width: 767px) {
    .o-footer {
      padding: 40px 0 20px; }
      .o-footer .container {
        max-width: none; }
      .o-footer__links {
        margin-bottom: 40px; }
      .o-footer .col.o-footer__links-tools {
        flex: 1 0 100%; }
      .o-footer .col.o-footer__links-features {
        flex: 1 0 100%; }
      .o-footer .col.o-footer__links-services {
        flex: 1 0 100%; }
      .o-footer .col.o-footer__links-resources {
        flex: 1 0 100%; }
      .o-footer .col.o-footer__links-company {
        flex: 1 0 100%; }
      .o-footer .col ul {
        margin-bottom: 40px; }
      .o-footer__brand div div {
        flex-direction: column; }
      .o-footer__logo {
        margin-bottom: 14px; }
      .o-footer__legal {
        text-align: left;
        align-items: flex-start; } }

@media (min-width: 768px) and (max-width: 1199px) {
  .o-footer__links {
    max-width: 720px;
    margin-bottom: 60px; }
    .o-footer__links ul {
      margin-bottom: 40px; } }

/* Cookies Popup */
.cookies-popup {
  display: none;
  background: #ffffff;
  padding: 18px 20px 20px;
  width: 100%;
  position: fixed;
  z-index: 2147483002;
  bottom: 20px;
  left: 20px;
  color: #28353d;
  border-radius: 7px;
  border: 1px solid rgba(40, 53, 61, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  max-width: 390px; }
  .cookies-popup__heading {
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    margin: 0 0 10px; }
  .cookies-popup p {
    font-size: 12px;
    line-height: 1.46;
    font-weight: 300; }
  .cookies-popup a {
    cursor: pointer;
    letter-spacing: normal;
    color: #447b20;
    text-decoration: underline;
    transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s; }
    .cookies-popup a:focus, .cookies-popup a:hover, .cookies-popup a:active {
      color: #28353d;
      text-decoration: underline; }
  .cookies-popup__btn {
    min-width: 100px; }
    .cookies-popup__btn:first-of-type {
      margin-right: 10px; }

@media (max-width: 575px) {
  .cookies-popup {
    max-width: 100%;
    left: 0px;
    bottom: 0px;
    border-radius: 0px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5); }
    .cookies-popup__btn {
      min-width: auto;
      width: 100%; }
      .cookies-popup__btn:first-of-type {
        margin: 0 0 10px; } }

/*
  clouddflare did not like output.min.css
  had to use separate stylesheet
*/
html {
  height: 100%; }

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased; }

main {
  padding: 258px 0 383px;
  flex: 1 0 auto;
  color: #28353d;
  background-color: #ffffff; }

.cloudflare-content a:not(.btn) {
  cursor: pointer;
  letter-spacing: normal;
  color: #447b20;
  text-decoration: underline;
  transition: color ease-in-out 0.3s, border-color ease-in-out 0.3s; }
  .cloudflare-content a:not(.btn):focus, .cloudflare-content a:not(.btn):hover, .cloudflare-content a:not(.btn):active {
    color: #28353d;
    text-decoration: underline; }

.cloudflare-content .btn--home {
  margin-top: 19px; }

.cloudflare-content h1 {
  font-size: 56px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.4px;
  color: #28353d;
  margin-bottom: 32px; }
  @media (max-width: 767px) {
    .cloudflare-content h1 {
      font-size: 34px;
      letter-spacing: -0.85px; } }
  .cloudflare-content h1 span.cloudflare-highlight {
    color: #5ba42b; }
  .cloudflare-content h1 + .btn--home {
    margin-top: 0; }

.cloudflare-content h3 {
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.8px;
  color: #28353d; }
  @media (max-width: 767px) {
    .cloudflare-content h3 {
      font-size: 26px;
      line-height: 1.23;
      letter-spacing: -0.65px; } }

.cloudflare-content p {
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #28353d; }
  @media (max-width: 767px) {
    .cloudflare-content p {
      font-size: 16px;
      line-height: 1.63; } }

.cloudflare-content ul {
  list-style: none;
  padding-left: 20px; }
  .cloudflare-content ul li {
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #28353d; }
    @media (max-width: 767px) {
      .cloudflare-content ul li {
        font-size: 16px;
        line-height: 1.63; } }
    .cloudflare-content ul li::before {
      content: "\2022";
      color: #5ba42b;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em; }

.cloudflare-content #recaptcha_widget {
  margin-bottom: 20px; }

.cloudflare-content input[type="submit"] {
  margin-bottom: 10px;
  text-align: center;
  border: none;
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: -0.00625em;
  height: 36px;
  color: #ffffff;
  background-color: #5ba42b;
  transition: background-color ease-in-out 0.3s; }
  .cloudflare-content input[type="submit"]:hover {
    color: #ffffff;
    background-color: #509025;
    border: none; }
  .cloudflare-content input[type="submit"]:focus {
    color: #ffffff;
    background-color: #447b20;
    border: none; }

@media (max-width: 991px) {
  main {
    padding: 320px 0 457px; } }

@media (min-width: 992px) {
  .o-header--desktop .o-header__logo a {
    background-image: url("../images/fw-logo-error.svg");
    background-repeat: no-repeat;
    background-position: left -3px center; }
  .navbar-brand-animated svg {
    display: none; } }

@media (max-width: 767px) {
  main {
    padding: 203px 0 247px; }
  .cloudflare-content h1 {
    margin-bottom: 26px; } }
