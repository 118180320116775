.o-header-dropdown-wrapper__content {
  perspective: 1000px;
  transition: visibility $anim-speed, opacity $anim-speed;
  visibility: hidden;
  border-radius: 4px;
}

.o-header-dropdown-triangle {
  transform: translateZ(0);
  visibility: hidden;
  opacity: 0;
  transition: visibility $anim-speed, opacity $anim-speed;
  position: relative;
  z-index: 2;
  pointer-events: none;

  &__image {
    width: 12px;
    height: 8px;
    background: url(../images/icons/dropdown-triangle.svg) no-repeat center;
    margin: auto;
    visibility: hidden;
    opacity: 0;
    transition: visibility $anim-speed, opacity $anim-speed;
    position: relative;
    top: 1px;
    z-index: 2;
  }
}

.o-header__dropdown-container {
  position: relative;
  height: 126px;
  background-color: $white-two;
  z-index: 10000;

  &::before {
    /* never visible - used in JS to check mq */
    content: 'mobile';
    display: none;
  }

  .o-header__main-nav {
    display: none;
  }

  .o-header-dropdown-wrapper {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 1.2em 5%;
  }

  &.nav-open .o-header-dropdown-wrapper {
    display: block;
  }

  .o-header-dropdown-wrapper__dropdown-list>ul>li {
    margin-bottom: 3.3em;
  }

  .links .content>ul>li {
    margin-top: 1em;
  }

  &::before {
    content: 'desktop';
  }

  .nav-trigger {
    display: none;
  }

  .o-header__main-nav {
    display: flex;
    margin-right: 30px;

    ul {
      margin: 0;
    }
  }

  .o-header__main-nav>ul>li {
    display: inline-block;
    float: left;
  }

  .o-header__main-nav>ul>li>a {
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 15px;
    line-height: 1;
    color: $dark;
    transition: color ease-in-out $anim-speed;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      // color: #53626B;
      color: $mercury-70;

      svg {
        stroke: $mercury-70;
      }
    }

    i {
      width: 20px;
      height: 20px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        stroke: #28353d;
        transition: stroke ease-in-out $anim-speed;
      }
    }
  }

  &.is-dropdown-visible .o-header__main-nav>ul>li>a {
    /* main navigation hover effect - on hover, reduce opacity of elements not hovered over */
    opacity: 1;
  }

  &.is-dropdown-visible .o-header__main-nav>ul>li.active>a {
    opacity: 1;
  }

  .o-header-dropdown-wrapper {
    /* dropdown wrapper - used to create the slide up/slide down effect when dropdown is revealed/hidden */
    display: block;
    top: 100px;
    width: auto;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    /* Force Hardware acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform $anim-speed;
  }

  &.is-dropdown-visible .o-header-dropdown-wrapper {
    transform: translateY(0);
  }

  .o-header-dropdown-wrapper__dropdown-list {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    transform: rotateX(-15deg);
    will-change: transform, width, height;
    transition: visibility $anim-speed, opacity $anim-speed, transform $anim-speed;
    opacity: 0;
    transform-origin: top center;

    .no-csstransitions & {
      display: none;
    }

    &:after {
      border: solid 1px $light-peach-two;
      box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
      border-radius: 4px;
      content: '';
      position: absolute;
      width: calc(100% + 6px);
      height: calc(100% + 1px);
      top: 0px;
      left: -2px;
      top: 0;
      background: $white-two;
    }

    >ul {
      position: relative;
      z-index: 1;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }

  &.is-dropdown-visible .o-header-dropdown-wrapper__dropdown-list {
    visibility: visible;
    transition: transform $anim-speed, width $anim-speed, height $anim-speed, opacity $anim-speed;
    opacity: 1;
    transform: rotateX(0);

    &::before {
      opacity: 1;
    }
  }

  &.is-dropdown-visible .o-header-dropdown-wrapper__content {
    visibility: visible;
    transition: transform $anim-speed, width $anim-speed, height $anim-speed, opacity $anim-speed;
    opacity: 1;

    &::before {
      opacity: 1;
    }
  }

  &.is-dropdown-visible .o-header-dropdown-triangle__image {
    visibility: visible;
    transition: transform $anim-speed, width $anim-speed, height $anim-speed, opacity $anim-speed;
    opacity: 1;
  }

  &.is-dropdown-visible .o-header-dropdown-triangle {
    visibility: visible;
    transition: transform $anim-speed, width $anim-speed, height $anim-speed, opacity $anim-speed;
    opacity: 1;
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transition: opacity $anim-speed, visibility $anim-speed;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &.move-left .content {
      transform: translateX(-100px);
    }

    &.move-right .content {
      transform: translateX(100px);
    }
  }

  .label {
    /* hide the label on bigger devices */
    display: none;
  }

  .content {
    padding: 16px 20px 20px 24px;
    transition: transform $anim-speed;
    text-align: left;

    >ul {
      padding: 0;
    }

    >ul>li {
      display: block;
      width: 100%;
      margin-right: 4%;
      margin-top: 0;

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        -webkit-transition: color ease-in-out $anim-speed;
        transition: color ease-in-out $anim-speed;
        font-size: 13px;
        line-height: 1;
        color: $dark;
        text-decoration: none;
        padding-top: 14px;
        padding-bottom: 14px;
        position: relative;

        span {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -17px;
          display: flex;
          align-items: center;

          svg {
            stroke: #28353d;
            transform: rotate(-90deg);
            transition: transform ease-in-out $anim-speed, stroke ease-in-out $anim-speed, fill ease-in-out $anim-speed;
          }
        }

        i {
          width: 23px;
          height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          svg {
            transition: transform ease-in-out $anim-speed, stroke ease-in-out $anim-speed, fill ease-in-out $anim-speed;

            &.icon-fw,
            &.icon-design {
              stroke: #28353d;
            }

            &.icon-design {
              stroke: #28353d;
              fill: #ffffff;
            }
          }
        }

        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          color: $outer-space-20;

          span {
            svg {
              stroke: $outer-space-20;
            }
          }

          i,
          span {
            svg {
              fill: $outer-space-20;

              &.icon-fw {
                stroke: $outer-space-20;
              }

              &.icon-design {
                stroke: $outer-space-20;
                fill: $white;
              }

              #icon-arrow-small {
                stroke: $outer-space-20;
              }
            }
          }
        }
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }

  .links .content>ul>li {
    margin-top: 0;
  }

  .links .content,
  .button .content {
    width: 390px;
  }

  .o-header-dropdown-wrapper {
    .why {
        width: 380px;

        .content {
          display: flex;

          ul {
            margin-right: 34px;
            width: 46%;

            &:last-of-type {
              margin-right: 0;
              width: 55%;
            }
          }
        }
    }
        .solutions {
            width: 210px;
          }

          .company {
            width: 210px;
          }

  }



  .o-header-dropdown-wrapper__bg-layer {
    /* morph dropdown background */
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    background-color: $white-two;
    opacity: 0;
    transition: opacity $anim-speed;
    transform-origin: top left;
    transform: translateZ(0);
    will-change: transform;
    backface-visibility: hidden;
  }

  &.is-dropdown-visible .o-header-dropdown-wrapper__bg-layer {
    opacity: 1;
    transition: transform $anim-speed, opacity $anim-speed;
  }

  @media #{$c-lg-down} and #{$c-md-up} {
    .o-header-dropdown-wrapper {
      top: 78px;
    }
  }

  @media #{$c-lg-down} {
    position: absolute;
    height: 126px;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: transparent;
  }

  @media #{$c-lg-up} {
    position: absolute;
    height: 126px;
    left: 0;
    top: 0;
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: transparent;
  }
}
