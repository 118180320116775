/* Footer */
.o-footer {
  padding: 100px 0;
  background-color: $dark-two;

  &-container {
    height: auto;
    padding: 20px 0px 50px 0px;
    background: $primary-60;
    border-top: 5px solid $highlight-60;
    z-index: 1;
  }

  &__legal {
    font-size: 12px;
    line-height: 1.33;
    color: $white;
    text-align: right;
    display: flex;
    align-items: flex-end;

    p {
      margin: 0;
    }
  }

  h4 {
    color: $white;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
  }

  &__links {
    margin-bottom: 84px;

    ul {
      color: $white;
      list-style: none;
      padding: 0;

      li {
        a {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: -0.35px;
          color: $cool-grey;
          text-decoration: none;
          transition: color ease-in-out $anim-speed;

          &:hover {
            color: $white-two;
          }
        }
      }
    }

    .col {
      &.o-footer__links {
        &-tools {
          flex: 0 1 160px;
        }

        &-features {
          flex: 0 1 220px;
        }

        &-services {
          flex: 0 1 160px;
        }

        &-resources {
          flex: 0 1 160px;
        }

        &-company {
          flex: 0 1 160px;
        }
      }
    }

    & span {
      color: $highlight-60;
      display: inline;
      margin: 0px 10px 0px 10px;
    }
  }

  @media #{$c-md-down} {
    padding: 40px 0 20px;

    .container {
      max-width: none;
    }

    &__links {
      margin-bottom: 40px;
    }

    .col {
      &.o-footer__links {
        &-tools {
          flex: 1 0 100%;
        }

        &-features {
          flex: 1 0 100%;
        }

        &-services {
          flex: 1 0 100%;
        }

        &-resources {
          flex: 1 0 100%;
        }

        &-company {
          flex: 1 0 100%;
        }
      }

      ul {
        margin-bottom: 40px;
      }
    }

    &__brand div div {
      flex-direction: column;
    }

    &__logo {
      margin-bottom: 14px;
    }

    &__legal {
      text-align: left;
      align-items: flex-start;
    }
  }
}

@media #{$c-md-up} and #{$c-xl-down} {
  .o-footer {
    &__links {
      max-width: 720px;
      margin-bottom: 60px;

      ul {
        margin-bottom: 40px;
      }
    }
  }
}
