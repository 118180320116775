/* Cookies Popup */

.cookies-popup {
  display: none;
  background: $white;
  padding: 18px 20px 20px;
  width: 100%;
  position: fixed;
  z-index: 2147483002; // just one above intercom
  bottom: 20px;
  left: 20px;
  color: $dark;
  border-radius: 7px;
  border: 1px solid rgba(40, 53, 61, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  max-width: 390px;
  &__heading {
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    margin: 0 0 10px;
  }
  & p {
    font-size: 12px;
    line-height: 1.46;
    font-weight: 300;
  }
  & a {
    @include link-style();
  }
  &__btn {
    min-width: 100px;
    &:first-of-type {
      margin-right: 10px;
    }
  }
}

@media #{$c-sm-down} {
  .cookies-popup {
  max-width: 100%;
  left: 0px;
  bottom: 0px;
  border-radius: 0px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.5);
  &__btn {
    min-width: auto;
    width: 100%;
    &:first-of-type {
      margin: 0 0 10px;
    }
  }
  }
}
