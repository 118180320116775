@import 'base_new/colours';
@import 'base_new/vars';
@import 'base_new/typography';
@import 'components_new/buttons';
@import 'components_new/header';
@import 'components_new/hamburger';
@import 'components_new/dropdown-menu';
@import 'components_new/nav-dropdown';
@import 'components_new/footer';
@import 'components/cookies';

/*
  clouddflare did not like output.min.css
  had to use separate stylesheet
*/

html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Heebo", sans-serif;
  -webkit-font-smoothing: antialiased;
}

main {
  padding: 258px 0 383px;
  flex: 1 0 auto;
  color: $dark;
  background-color: $white;
}



.cloudflare-content {
  & a:not(.btn) {
    @include link-style;
  }
  & .btn--home {
    margin-top: 19px;
  }
  & h1 {
    @include heading-1;
    margin-bottom: 32px;
    & span.cloudflare-highlight {
      color: $sushi-60;
    }
    & + .btn--home {
      margin-top: 0;
    }
  }
  & h3 {
    @include heading-3;
  }
  & p {
    @include body-1;
  }
  & ul {
  list-style: none;
  padding-left: 20px;
    & li {
      @include body-1;
      &::before {
        content: "\2022";
        color: $sushi-60;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  & #recaptcha_widget {
    margin-bottom: 20px;
  }
  & input[type="submit"] {
    margin-bottom: 10px;
    text-align: center;
    border: none;
    padding: 7px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: -0.00625em;
    height: 36px;
    color: $white;
    background-color: $sushi-60;
    transition: background-color ease-in-out $anim-speed;
    &:hover {
      color: $white;
      background-color: $sushi-70;
      border: none;
    }
    &:focus {
      color: $white;
      background-color: $sushi-80;
      border: none;
    }
  }
} // end cloudflare



@media #{$c-lg-down} {
  main {
    padding: 320px 0 457px;
  }
}

@media #{$c-lg-up} {
  .o-header--desktop .o-header__logo a {
    background-image: url("../images/fw-logo-error.svg");
    background-repeat: no-repeat;
    background-position: left -3px center;
  }
  .navbar-brand-animated svg {
    display: none;
  }
}

@media #{$c-md-down} {
  main {
    padding: 203px 0 247px;
  }
  .cloudflare-content {
    & h1 {
      margin-bottom: 26px;
    }
  }
}
