.nav-dropdown-menu {
  display: flex;
  position: absolute;
  transform-origin: left top;
  background-color: rgb(255, 255, 255);
  left: 0;
  height: 0;
  width: 0;
  will-change: transform, height, width;
//   transform: translateX(0px);
//  transition: transform ease-in-out .3s, opacity ease-in-out .3s;
animation: 0.3s ease 0s 1 normal forwards running FlChO;
  box-shadow: 0 6px 11px -1px rgba(0, 0, 0, 0.09);
  border: solid 1px #d3cece;
  background-color: #fcfcfc;
  border-radius: 4px;
  top: 42px;


  // display: none;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -9px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
    transform: rotate(90deg);
  }

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 1px);
    top: -12px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #d3cece;
    transform: rotate(90deg);
  }

  &[aria-hidden="false"] {
    width: 414px;
    height: 287px;
  transform: translateX(-43px);
 animation: 0.3s ease 0s 1 normal forwards running test;
 //   transform: translateX(-43px) rotateX(-20deg);
    left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 24px;
    padding-right: 0;
  }
  &[aria-hidden="true"] {
      padding: 0;
      &:after, &:before {
          display: none;
      }
    ul {
        display: none;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    flex: 1 0 auto;
  }

  &__list-item {
    margin-bottom: 28px;

    >ul>li {
      margin-bottom: 21px;
      padding-left: 35px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    button,
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: color ease-in-out $anim-speed;
      color: #28353d;
      font-size: 13px;
      padding-top: 14px;
      padding-bottom: 14px;

  
    }
    a,
    button {
      color: $dark;
      font-size: 13px;
      padding-top: 14px;
      padding-bottom: 14px;

      i {
        margin-right: 13px;
     

        svg {
          fill: $dark;

          &.nav-dropdown-menu__list-item-wurks {
            stroke: $dark;
          }

          &.nav-dropdown-menu__list-item-design {
            stroke: $dark;
            fill: $white;
          }
        }
      }

      &:focus,
      &:active,
      &:hover {
        text-decoration: none;
        color: $outer-space-20;

        i {
          svg {
            fill: $outer-space-20;

            &.nav-dropdown-menu__list-item-wurks {
              stroke: $outer-space-20;
            }

            &.nav-dropdown-menu__list-item-design {
              stroke: $outer-space-20;
              fill: $white;
            }
          }
        }
      }
    }
    .subnav {
      display: flex;
      flex-direction: column;

      &__list-item {
        display: flex;
      }
    }

    i {
      width: 23px;
      height: 23px;
      min-width: 23px;
      min-height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    &--tech {
      >.subnav {
        margin-top: 20px;
        margin-bottom: 15px;
      }

      >a,
      >button {
        position: relative;
        padding-left: 0;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          width: 23px;
          height: 23px;
        }

        &:after {
          content: "";
          position: absolute;
          right: -17px;
          background: url(../images/icons/icon-arrow-small.svg) no-repeat center;
          width: 20px;
          height: 20px;
          transition: transform ease-in-out $anim-speed, background ease-in-out $anim-speed;
        }

        &:hover,
        &:active,
        &:focus {
          &:after {
            background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
          }
        }

        &[data-expanded="true"] {
          color: #A8AEB1;

          &:after {
            background: url(../images/icons/icon-arrow-small-hover.svg) no-repeat center;
            transform: rotate(-180deg);
          }

          i {
            svg {
              fill: #A8AEB1;
            }
          }
        }
      }
    }
  }

  .subnav {
    margin-top: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      top: -12px;
      bottom: -6px;
      left: 9px;
      background: #32424C;
    }
  }

  ul {
    transition: opacity ease-in .3s;
    opacity: 1;
    min-width: 206px;

    li {
      padding-right: 24px;
      margin-bottom: 0;
    }
  }

  ul[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
}

@keyframes FlChO {
  0% {
    transform: translateX(0) rotateX(0deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes test {
  0% {
    transform: translateX(-43px) rotateX(-20deg);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
